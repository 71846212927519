import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { format } from "date-fns";
import { GET_UPCOMING_TRANSACTIONS } from "../queries/recurringQueries";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { Separator } from "./ui/Separator";
import { Spinner } from "./ui/Spinner";
import { calculateHowManyDaysTillTargetDate } from "../lib/utils";
import * as LucideIcons from "lucide-react";

const EmptyState = ({ message }: { message: string }) => (
  <div className="flex flex-col items-center justify-center p-8 text-center">
    <div className="h-12 w-12 rounded-full bg-gray-100 flex items-center justify-center mb-4">
      <i className="fas fa-calendar text-gray-400" />
    </div>
    <p className="text-gray-500">{message}</p>
  </div>
);

const TransactionCard = ({ transaction }) => {
  const IconComponent =
    transaction.category?.icon &&
    LucideIcons[transaction.category.icon as keyof typeof LucideIcons]
      ? LucideIcons[transaction.category.icon as keyof typeof LucideIcons]
      : LucideIcons.HelpCircle;

  return (
    <div className="bg-white rounded-lg shadow p-4 flex justify-between items-center hover:bg-gray-50 transition-colors">
      <div className="flex items-center gap-4">
        <div className="w-10 h-10 bg-gray-50 rounded-full flex items-center justify-center">
          <IconComponent className="h-5 w-5 text-gray-600" />
        </div>
        <div>
          <h3 className="font-semibold">{transaction.description}</h3>
          <div className="flex flex-col sm:flex-row sm:gap-2 text-sm text-gray-600">
            <span>{transaction.category.name}</span>
            <span className="hidden sm:inline">•</span>
            <span>
              {transaction.account?.name || "Unknown Account"}
              {transaction.account?.mask && ` (*${transaction.account.mask})`}
            </span>
          </div>
        </div>
      </div>
      <div className="text-right">
        <p
          className={`font-semibold ${transaction.lastAmount < 0 ? "text-emerald-600 dark:text-emerald-400" : "text-red-600 dark:text-red-40"}`}
        >
          {transaction.lastAmount < 0 ? "+" : "-"}$
          {(Math.abs(transaction.lastAmount) / 100).toFixed(2)}
        </p>
        <p className="text-sm text-gray-600 font-semibold">
          Expected on:{" "}
          {format(new Date(transaction.predictedNextDate), "MMM d, yyyy")}
        </p>
      </div>
    </div>
  );
};

const TransactionSection = ({ title, transactions }) => (
  <Card className="w-full">
    <CardHeader>
      <CardTitle>{title}</CardTitle>
    </CardHeader>
    <CardContent>
      <div className="grid gap-4">
        {transactions.length > 0 ? (
          transactions.map((transaction) => (
            <TransactionCard key={transaction.id} transaction={transaction} />
          ))
        ) : (
          <EmptyState message="No upcoming transactions for this period" />
        )}
      </div>
    </CardContent>
  </Card>
);

const UpcomingTransactionsHome = () => {
  const { data, loading, error } = useQuery(GET_UPCOMING_TRANSACTIONS, {
    variables: { daysAhead: 45 },
  });

  const {
    twoWeekTransactions,
    thirtyDayTransactions,
    fortyFiveDayTransactions,
  } = useMemo(() => {
    if (!data?.upcomingTransactions)
      return {
        twoWeekTransactions: [],
        thirtyDayTransactions: [],
        fortyFiveDayTransactions: [],
      };

    const sorted = [...data.upcomingTransactions].sort(
      (a, b) =>
        new Date(a.predictedNextDate).getTime() -
        new Date(b.predictedNextDate).getTime()
    );

    return sorted.reduce(
      (acc, transaction) => {
        const daysUntil = calculateHowManyDaysTillTargetDate(
          transaction.predictedNextDate
        );

        if (daysUntil <= 14) {
          acc.twoWeekTransactions.push(transaction);
        } else if (daysUntil <= 30) {
          acc.thirtyDayTransactions.push(transaction);
        } else if (daysUntil <= 45) {
          acc.fortyFiveDayTransactions.push(transaction);
        }

        return acc;
      },
      {
        twoWeekTransactions: [],
        thirtyDayTransactions: [],
        fortyFiveDayTransactions: [],
      }
    );
  }, [data]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500">
        Error loading recurring transactions: {error.message}
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full w-full overflow-auto">
      <div className="flex py-3">
        <div className="flex flex-col w-full">
          <div className="flex flex-col items-center px-4 pb-4">
            <div className="flex flex-row items-center justify-between w-full">
              <h2 className="text-xl font-semibold">Upcoming Transactions</h2>
            </div>
          </div>
          <Separator />
          <div className="p-4 grid gap-6">
            <TransactionSection
              title="Next 2 Weeks"
              transactions={twoWeekTransactions}
            />
            <TransactionSection
              title="Next 30 Days"
              transactions={thirtyDayTransactions}
            />
            <TransactionSection
              title="Next 45 Days"
              transactions={fortyFiveDayTransactions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingTransactionsHome;
